import { isDefined } from "@clipboard-health/util-ts";
import { Avatar, Box } from "@mui/material";
import { useWorkerProfilePicture } from "@src/appV2/Agents/api/useWorkerProfilePicture";
import { BLUE_GREEN_B8 } from "@src/appV2/lib/Theme/constants";

import { type WorkerInfoType, WorkerProfilePictureStatus } from "../api/useWorkplaceComments";

interface WorkerAvatarProps {
  workerInfo: WorkerInfoType;
}

export function WorkerAvatar(props: WorkerAvatarProps) {
  const { workerInfo } = props;
  const { data: agentProfilePicture } = useWorkerProfilePicture(workerInfo.id ?? "", {
    enabled:
      isDefined(workerInfo.id) &&
      workerInfo.profilePictureStatus === WorkerProfilePictureStatus.EXISTS_IN_FILE_STORAGE,
  });

  return (
    <Box>
      <Avatar
        aria-label="Worker Avatar"
        alt=""
        sx={{
          backgroundColor: BLUE_GREEN_B8,
          color: (theme) => theme.palette.common.white,
        }}
        src={agentProfilePicture?.distribution_url}
      />
    </Box>
  );
}
