import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import { Button, CardActions, List, ListItem, Skeleton } from "@mui/material";
import { FacilityDetailsCollapse } from "@src/appV2/Facilities/components/FacilityDetailsCollapse";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { ReviewsSummaryDialog } from "@src/appV2/Reviews/components/Dialogs";
import { truncate } from "lodash";

import { useGetMultipleChoiceAggregates } from "../../api/useGetMultipleChoiceAggregates";
import { useWorkplaceComments } from "../../api/useWorkplaceComments";
import {
  PARKING_REVIEW_SUMMARY_PREFIX,
  REVIEW_SUMMARY_MAX_LENGTH,
  REVIEWS_SUMMARY_SCREEN_NAME,
} from "../../constants";
import { QuestionTag, type WorkplaceDetails } from "../../types";
import { tagScreenNameDeprecated } from "../../utils";

export interface ReviewSummaryWorkplaceDetailsProps {
  workPlaceDetails: WorkplaceDetails;
  screenName: string;
}

export function ReviewSummaryWorkplaceDetails(props: ReviewSummaryWorkplaceDetailsProps) {
  const { workPlaceDetails, screenName } = props;
  const reviewSummaryModalState = useModalState();
  const { showErrorToast } = useToast();

  // fetch data to display patient per nurse aggregate summary
  const { data: multipleChoiceAggregates = [], isLoading: isLoadingMultipleChoiceAggregates } =
    useGetMultipleChoiceAggregates(workPlaceDetails.userId, {
      onError: () => {
        showErrorToast("Something went wrong while loading patients per nurse review");
      },
    });
  // FIXME: Currently we are doing this calculation in frontend because we don't have option to
  // pass QuestionTag in query params to API. Once API supports passing QuestionTag, we can remove
  // this logic
  const patientsPerNurseAggregate = multipleChoiceAggregates.find((aggregate) =>
    aggregate.attributes.question.tags.some(({ tag }) => tag === QuestionTag.PATIENTS_PER_NURSE)
  );

  // fetch data to display most liked parking review
  // Backend sort the response by most liked, so here we are fetching only one parking
  // related review and displaying it as summary
  const { data: parkingComments = [], isLoading: isLoadingParkingComments } = useWorkplaceComments(
    {
      workplaceId: workPlaceDetails.userId,
      filter: { questionTags: [QuestionTag.PARKING] },
      page: { size: 1 },
    },
    {
      onError: () => {
        showErrorToast("Something went wrong while loading parking review");
      },
    }
  );

  // fetch data to display most liked review
  // Backend sort the response by most liked, so here we are fetching only one review
  // and displaying it as summary
  const { data: comments = [], isLoading: isLoadingComments } = useWorkplaceComments(
    {
      workplaceId: workPlaceDetails.userId,
      page: { size: 1 },
    },
    {
      onError: () => {
        showErrorToast("Something went wrong while loading review");
      },
    }
  );

  if (isLoadingMultipleChoiceAggregates && isLoadingParkingComments && isLoadingComments) {
    return <Skeleton variant="rectangular" width="100%" height={96} />;
  }

  const reviewSummaries: string[] = [
    patientsPerNurseAggregate?.attributes.text,
    parkingComments[0] &&
      `${PARKING_REVIEW_SUMMARY_PREFIX} ${parkingComments?.[0]?.attributes.text}`,
    comments?.[0]?.attributes.text,
    // type check is unable to infer isDefined method if used as per recommended
    // by unicorn/no-array-callback-reference
  ].filter(isDefined);

  // If there are no review summaries to display, we don't want to display an empty Card
  if (reviewSummaries.length === 0) {
    return null;
  }

  return (
    <>
      <FacilityDetailsCollapse
        isExpandedByDefault
        disableToggle
        icon={<ReviewsOutlinedIcon />}
        title="Reviews"
      >
        <List
          dense
          disablePadding
          sx={{
            listStyleType: "disc",
            listStylePosition: "outside",
          }}
        >
          {reviewSummaries.map((review) => {
            return (
              <ListItem
                key={review}
                disablePadding
                sx={{
                  display: "list-item",
                }}
              >
                {truncate(review, { length: REVIEW_SUMMARY_MAX_LENGTH })}
              </ListItem>
            );
          })}
        </List>

        <CardActions
          sx={{
            marginBottom: -2,
            padding: 0,
            flexDirection: "column",
          }}
        >
          <Button
            variant="text"
            size="small"
            endIcon={<ExpandMoreIcon />}
            onClick={async () => {
              await tagScreenNameDeprecated(REVIEWS_SUMMARY_SCREEN_NAME);
              reviewSummaryModalState.openModal();
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_SCREEN_OPENED, {
                workplaceId: workPlaceDetails.userId,
              });
            }}
          >
            View more reviews
          </Button>
        </CardActions>
      </FacilityDetailsCollapse>

      <ReviewsSummaryDialog
        modalState={reviewSummaryModalState}
        workPlaceDetails={workPlaceDetails}
        screenName={screenName}
      />
    </>
  );
}
